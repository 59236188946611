const characterInfo = [
    { img: [
        "./imgs/Characters/PC/angry monkey.webp"
    ],
    dimensions: [
        [700,1000]
    ],
    size: [0.7]
    },
    { img: [
        "./imgs/Characters/PC/Happy Cat.webp"
    ],
    dimensions: [
        [752,854]
    ],
    size: [0.6]
    },
    { img: [
        "./imgs/Characters/PC/headless robber.webp"
    ],
    dimensions: [
        [768,1000]
    ],
    size: [0.9]
    },
    { img: [
        "./imgs/Characters/PC/monkey.webp"
    ],
    dimensions: [
        [834,946]
    ],
    size: [1]
    },
    { img: [
        "./imgs/Characters/PC/mouse.webp"
    ],
    dimensions: [
        [100,114]
    ],
    size: [0.5]
    },
    { img: [
        "./imgs/Characters/PC/obnoxious octopus.webp"
    ],
    dimensions: [
        [633,452]
    ],
    size: [1.2]
    },
    { img: [
        "./imgs/Characters/PC/purple raptor.webp"
    ],
    dimensions: [
        [1000,750]
    ],
    size: [1.8]
    },
    { img: [
        "./imgs/Characters/PC/wind legend boss.webp"
    ],
    dimensions: [
        [581,530]
    ],
    size: [1]
    },
    { img: [
        "./imgs/Characters/PC/Worm Birdy.webp"
    ],
    dimensions: [
        [2235,2134]
    ],
    size: [0.5]
    },
    { img: [
        "./imgs/Characters/PC/bank manager.webp"
    ],
    dimensions: [
        [718,926]
    ],
    size: [0.9]
    },
    { img: [
        "./imgs/Characters/PC/shark.webp"
    ],
    dimensions: [
        [300,298]
    ],
    size: [1]
    },
    { img: [
        "./imgs/Characters/PC/BorisTheBaconKing.webp"
    ],
    dimensions: [
        [577,998]
    ],
    size: [1]
    },
    { img: [
        "./imgs/Characters/PC/DemonKurdie.webp"
    ],
    dimensions: [
        [290,237]
    ],
    size: [0.7]
    },
    { img: [
        "./imgs/Characters/PC/dog.webp"
    ],
    dimensions: [
        [244,326]
    ],
    size: [0.9]
    },
    { img: [
        "./imgs/Characters/PC/Donut.webp"
    ],
    dimensions: [
        [257,249]
    ],
    size: [0.6]
    },
    { img: [
        "./imgs/Characters/PC/dragon.webp"
    ],
    dimensions: [
        [2004,1755]
    ],
    size: [1.8]
    },
    { img: [
        "./imgs/Characters/PC/ElspethTheElf.webp"
    ],
    dimensions: [
        [301,188]
    ],
    size: [1]
    },
    { img: [
        "./imgs/Characters/PC/FramptonTheHero.webp"
    ],
    dimensions: [
        [301,218]
    ],
    size: [1]
    },
    { img: [
        "./imgs/Characters/PC/Higgins.webp"
    ],
    dimensions: [
        [300,152]
    ],
    size: [1.4]
    },
    { img: [
        "./imgs/Characters/PC/scuba lama.webp"
    ],
    dimensions: [
        [289,290]
    ],
    size: [1.3]
    },
    { img: [
        "./imgs/Characters/PC/yeeet.webp"
    ],
    dimensions: [
        [257,300]
    ],
    size: [0.9]
    },
    { img: [
        "./imgs/Characters/PC/Ogre.webp"
    ],
    dimensions: [
        [300,212]
    ],
    size: [1.2]
    },
    { img: [
        "./imgs/Characters/PC/FishingFrog.webp"
    ],
    dimensions: [
        [300,227]
    ],
    size: [1]
    },
    { img: [
        "./imgs/Characters/PC/HotDogCat.webp"
    ],
    dimensions: [
        [300,240]
    ],
    size: [1]
    },
    { img: [
        "./imgs/Characters/PC/bubbledude.webp"
    ],
    dimensions: [
        [700,700]
    ],
    size: [0.6]
    },
    { img: [
        "./imgs/Characters/PC/floating duck.webp"
    ],
    dimensions: [
        [464,778]
    ],
    size: [1]
    },
    { img: [
        "./imgs/Characters/PC/Hungry Bear.webp"
    ],
    dimensions: [
        [898,1000]
    ],
    size: [0.9]
    },
    { img: [
        "./imgs/Characters/PC/lamb.webp"
    ],
    dimensions: [
        [605,600]
    ],
    size: [0.8]
    },
    { img: [
        "./imgs/Characters/PC/Lim.webp"
    ],
    dimensions: [
        [600,600]
    ],
    size: [0.8]
    },
    { img: [
        "./imgs/Characters/PC/orcat.webp"
    ],
    dimensions: [
        [722,700]
    ],
    size: [1.2]
    },
    { img: [
        "./imgs/Characters/PC/PabloThePenguin.webp"
    ],
    dimensions: [
        [482,609]
    ],
    size: [0.6]
    },
    { img: [
        "./imgs/Characters/PC/pineapple.webp"
    ],
    dimensions: [
        [278,600]
    ],
    size: [0.7]
    },
    { img: [
        "./imgs/Characters/PC/Racoon.webp"
    ],
    dimensions: [
        [700,700]
    ],
    size: [0.8]
    },
    { img: [
        "./imgs/Characters/PC/RobertTheGrocer.webp"
    ],
    dimensions: [
        [442,759]
    ],
    size: [1.4]
    },
    { img: [
        "./imgs/Characters/PC/TimmyTheTerror.webp"
    ],
    dimensions: [
        [600,803]
    ],
    size: [0.9]
    }

]

export default characterInfo