
import React from 'react';
import $ from 'jquery';
import "./index.css";

var orientation;
var mobileImgSize = 2098/4023;

class Contact extends React.Component{

    componentDidMount(){

        var DaveInfo = document.getElementById("DaveInfo").innerHTML;
        var AlixInfo = document.getElementById("AlixInfo").innerHTML;
        var LindseyInfo = document.getElementById("LindsInfo").innerHTML;
        var JordynInfo = document.getElementById("JordynInfo").innerHTML;
        var SkyeInfo = document.getElementById("SkyeInfo").innerHTML;

        function positionProfiles(){
            if (window.innerHeight > window.innerWidth){
                if (orientation !== "Portrait"){
                    //document.getElementById('gameFrame').src = "";
                    orientation = "Portrait"
                    $('#managersImg').attr('src', './imgs/Managers/managers-3.webp');
                    $('#managersHover').attr('src', './imgs/Managers/noHoverMobile.png');
                }

                var contactBlurbHeight = document.getElementById("contactBlurb").clientHeight;
                var pageHeight = document.getElementById("contactuspage").clientHeight;
                document.getElementById("managersImg").style.height = pageHeight - contactBlurbHeight + "px";
                document.getElementById("managersImg").style.top = contactBlurbHeight + "px";
                document.getElementById("managersHover").style.height = pageHeight - contactBlurbHeight + "px";
                document.getElementById("managersHover").style.top = contactBlurbHeight + "px";

                //console.log(document.getElementById("managersImg").width/0.514);
                document.getElementById('Dave').style.top = contactBlurbHeight + document.getElementById("managersImg").width/mobileImgSize*0.005 + "px";
                document.getElementById('Dave').style.left = "52%";
                document.getElementById('Alix').style.top = contactBlurbHeight + document.getElementById("managersImg").width/mobileImgSize*0.11 + "px";
                document.getElementById('Alix').style.left = "26%";
                document.getElementById('Lindsey').style.top = contactBlurbHeight + document.getElementById("managersImg").width/mobileImgSize*0.15 + "px";
                document.getElementById('Lindsey').style.left = "61%";
                document.getElementById('Skye').style.top = contactBlurbHeight + document.getElementById("managersImg").width/mobileImgSize*0.18 + "px";
                document.getElementById('Skye').style.left = "7.5%";
                document.getElementById('Jordyn').style.top = contactBlurbHeight + document.getElementById("managersImg").width/mobileImgSize*0.28 + "px";
                document.getElementById('Jordyn').style.left = "38.5%";

                //document.getElementById('game-wrapper').style.width = window.innerWidth*0.89+"px";
                //document.getElementById('game-wrapper').style.height = window.innerWidth*0.89/1.77778+"px";
                
                
            } else {
                if (orientation !== "Landscape"){
                    //document.getElementById('gameFrame').src = "./Godot Games/Catch Game/index.html";
                    orientation = "Landscape"
                    $('#managersImg').attr('src', './imgs/Managers/managers-2.webp');
                    $('#managersHover').attr('src', './imgs/Managers/noHover.png');
                }
                console.log(document.getElementById("managersImg").width);
                document.getElementById('Dave').style.top = document.getElementById("managersImg").width*0.75*18.5/100+"px";
                document.getElementById('Dave').style.left = "23%";
                document.getElementById('Alix').style.top = document.getElementById("managersImg").width*0.75*32/100+"px";;
                document.getElementById('Alix').style.left = "71%";
                document.getElementById('Lindsey').style.top = document.getElementById("managersImg").width*0.75*28/100+"px";;
                document.getElementById('Lindsey').style.left = "36.5%";
                document.getElementById('Skye').style.top = document.getElementById("managersImg").width*0.75*23/100+"px";;
                document.getElementById('Skye').style.left = "45.5%";
                document.getElementById('Jordyn').style.top = document.getElementById("managersImg").width*0.75*22/100+"px";;
                document.getElementById('Jordyn').style.left = "58.5%";

                //document.getElementById('game-wrapper').style.height = window.innerHeight*0.89+"px";
                //document.getElementById('game-wrapper').style.width = window.innerHeight*0.89*1.77778+"px";

                document.getElementById("DaveInfo").innerHTML = DaveInfo;
                document.getElementById("AlixInfo").innerHTML = AlixInfo;
                document.getElementById("LindsInfo").innerHTML = LindseyInfo;
                document.getElementById("JordynInfo").innerHTML = JordynInfo;
                document.getElementById("SkyeInfo").innerHTML = SkyeInfo;
            }
        }
        
        positionProfiles();

        window.addEventListener('resize', function(event){
            positionProfiles();
        });
        
    }

    hoverDave (){
        if (orientation === "Portrait"){
            $('#managersHover').attr('src', './imgs/Managers/DaveHoverMobile.png');
        } else {
            $('#managersHover').attr('src', './imgs/Managers/DaveHover.png');
        }
        document.getElementById('DaveInfo').style.visibility = 'visible';
    }
    unhoverDave (){
        if (orientation === "Portrait"){
            $('#managersHover').attr('src', './imgs/Managers/noHoverMobile.png');
        } else {
            $('#managersHover').attr('src', './imgs/Managers/noHover.png');
        }
        document.getElementById('DaveInfo').style.visibility = 'hidden';
    }

    hoverAlix (){
        if (orientation === "Portrait"){
            $('#managersHover').attr('src', './imgs/Managers/AlixHoverMobile.png');
        } else {
            $('#managersHover').attr('src', './imgs/Managers/AlixHover.png');
            }
        document.getElementById('AlixInfo').style.visibility = 'visible';
    }
    unhoverAlix (){
        if (orientation === "Portrait"){
            $('#managersHover').attr('src', './imgs/Managers/noHoverMobile.png');
        } else {
            $('#managersHover').attr('src', './imgs/Managers/noHover.png');
        }
        document.getElementById('AlixInfo').style.visibility = 'hidden';
    }

    hoverLindsey (){
        if (orientation === "Portrait"){
            $('#managersHover').attr('src', './imgs/Managers/LindseyHoverMobile.png');
        } else {
            $('#managersHover').attr('src', './imgs/Managers/LindseyHover.png');
        }
        document.getElementById('LindsInfo').style.visibility = 'visible';
    }
    unhoverLindsey (){
        if (orientation === "Portrait"){
            $('#managersHover').attr('src', './imgs/Managers/noHoverMobile.png');
        } else {
            $('#managersHover').attr('src', './imgs/Managers/noHover.png');
        }
        document.getElementById('LindsInfo').style.visibility = 'hidden';
    }

    hoverJordyn (){
        if (orientation === "Portrait"){
            $('#managersHover').attr('src', './imgs/Managers/JordynHoverMobile.png');
        } else {
            $('#managersHover').attr('src', './imgs/Managers/JordynHover.png');
            }
        document.getElementById('JordynInfo').style.visibility = 'visible';
    }
    unhoverJordyn (){
        if (orientation === "Portrait"){
            $('#managersHover').attr('src', './imgs/Managers/noHoverMobile.png');
        } else {
            $('#managersHover').attr('src', './imgs/Managers/noHover.png');
        }
        document.getElementById('JordynInfo').style.visibility = 'hidden';
    }

    hoverSkye (){
        if (orientation === "Portrait"){
            $('#managersHover').attr('src', './imgs/Managers/SkyeHoverMobile.png');
        } else {
            $('#managersHover').attr('src', './imgs/Managers/SkyeHover.png');
        }
        document.getElementById('SkyeInfo').style.visibility = 'visible';
    }
    unhoverSkye (){
        if (orientation === "Portrait"){
            $('#managersHover').attr('src', './imgs/Managers/noHoverMobile.png');
        } else {
            $('#managersHover').attr('src', './imgs/Managers/noHover.png');
        }
        document.getElementById('SkyeInfo').style.visibility = 'hidden';
    }

    

    render() {
        return (
            <div id='ContactUsContent'>
                {/*<div class="game"><div id="game-wrapper">
                    <iframe id="gameFrame" title="Bytalaga" height='100%' width='100%' allowtransparency="true" src='' sandbox='allow-same-origin allow-scripts allow-forms'/>
                </div></div>*/}
                <div id="contactBlurb">
                    <p id="title">Let's Start with print('Hello')</p>
                    <font id="smallBlurb">Need help registering? Questions about our camps? Want to see Byte Camp at your local school or recreation centre? We'd love to hear from you!</font><br/>
                    <div id="callNumber"><a style={{cursor: "pointer"}} id="callButton" href="tel:+18888082983">1-888-808-BYTE</a></div>
                </div>
                <div id="managersBox">
                    <img id="managersImg" alt='The Byte Camp Managers' src="./imgs/Managers/managers-2.webp"/>
                    <img id="managersHover" alt='mask' src="./imgs/Managers/noHover.png"/>
                </div>
                    <div id="Dave" class="profiles" onMouseOver={this.hoverDave} onMouseOut={this.unhoverDave} ><img id="DaveImg" class="managerImages" alt="Dave Hlannon Founder & Executive Director Extension 1" src="./imgs/Managers/Dave.png" /><div id="DaveInfo" class="infoBox"><b>Dave Hlannon</b><br/>Founder & Executive<br/>Director<br/>dave@bytecamp.ca<br/><b><span class="links"><a class='extension' href="tel:+18888082983,1"><img class="callIcon" style={{verticalAlign:"middle"}} alt="Call Dave" src="./imgs/Managers/phone_icon.png"/>&nbsp; ext 1 </a>&nbsp;&nbsp;| &nbsp;&nbsp;<a href="mailto:dave@bytecamp.ca"><img class="emailIcon" style={{verticalAlign:"middle"}} width="20%" alt="Email Dave" src="./imgs/Managers/email.png"/></a></span></b><br/></div></div>
                    <div id="Alix" class="profiles"  onMouseOver={this.hoverAlix} onMouseOut={this.unhoverAlix}><img id="AlixImg" class="managerImages" alt="Alix Voorthuyzen Operational & Technology Manager Extension 4" src="./imgs/Managers/Alix.png" /><div id="AlixInfo" class="infoBox"><b>Alix Voorthuyzen</b><br/>Operations &<br/>Technical Manager<br/>alix@bytecamp.ca<br/><b><span class="links"><a class='extension' href="tel:+18888082983,2"><img class="callIcon" style={{verticalAlign:"middle"}} alt="Call Alix" src="./imgs/Managers/phone_icon.png"/>&nbsp; ext 2 </a>&nbsp;&nbsp;| &nbsp;&nbsp; <a href="mailto:alix@bytecamp.ca"><img class="emailIcon" style={{verticalAlign:"middle"}} width="20%" alt="Email Alix" src="./imgs/Managers/email.png"/></a></span></b><br/></div></div>
                    <div id="Lindsey" class="profiles"  onMouseOver={this.hoverLindsey} onMouseOut={this.unhoverLindsey}><img id="LindseyImg" class="managerImages" alt="Lindsey Ashton Vancouver Island Regional Manager Extension 5" src="./imgs/Managers/Lindsey.png" /><div id="LindsInfo" class="infoBox"><b>Lindsey Ashton</b><br/>Vancouver Island <br/>Regional Manager<br/>lindsey@bytecamp.ca<br/><b><span class="links"><a class='extension' href="tel:+18888082983,5"><img class="callIcon" style={{verticalAlign:"middle"}} alt="Call Lindsey" src="./imgs/Managers/phone_icon.png"/>&nbsp; ext 5 </a>&nbsp;&nbsp;| &nbsp;&nbsp; <a href="mailto:lindsey@bytecamp.ca"><img class="emailIcon" style={{verticalAlign:"middle"}} width="20%" alt="Email Lindsey" src="./imgs/Managers/email.png"/></a></span></b><br/></div></div>
                    <div id="Jordyn" class="profiles" onMouseOver={this.hoverJordyn} onMouseOut={this.unhoverJordyn}><img id="JordynImg" class="managerImages" alt="Jordyn McCrea Vancouver Regional Manager Extension 8" src="./imgs/Managers/Jordyn.png" /><div id="JordynInfo" class="infoBox"><b>Jordyn McCrea</b><br/>Vancouver Regional Manager<br/>jordyn@bytecamp.ca<br/><b><span class="links"><a class='extension' href="tel:+18888082983,8"><img class="callIcon" style={{verticalAlign:"middle"}} alt="Call Jordyn" src="./imgs/Managers/phone_icon.png"/>&nbsp; ext 8 </a>&nbsp;&nbsp;| &nbsp;&nbsp; <a href="mailto:jordyn@bytecamp.ca"><img class="emailIcon" style={{verticalAlign:"middle"}} width="20%" alt="Email Jordyn" src="./imgs/Managers/email.png"/></a></span></b><br/></div></div>
                    <div id="Skye" class="profiles" onMouseOver={this.hoverSkye} onMouseOut={this.unhoverSkye}><img id="SkyeImg" class="managerImages" alt="Skye Ens Office Manager Extension 7" src="./imgs/Managers/Skye.png" /><div id="SkyeInfo" class="infoBox"><b>Skye Ens</b><br/>Office Manager<br/>skye@bytecamp.ca<br/><b><span class="links"><a class='extension' href="tel:+18888082983,7"><img class="callIcon" style={{verticalAlign:"middle"}} alt="Call Skye" src="./imgs/Managers/phone_icon.png"/>&nbsp; ext 7 </a>&nbsp;&nbsp;| &nbsp;&nbsp; <a href="mailto:skye@bytecamp.ca"><img class="emailIcon" style={{verticalAlign:"middle"}} width="20%" alt="Email Skye" src="./imgs/Managers/email.png"/></a></span></b><br/></div></div>
                
                <style>{"\
                #ContactUsContent{\
                    width: 100%;\
                    height: 100%;\
                }\
                .game{\
                    width: 100%;\
                    height: 100%;\
                    position:absolute;\
                }\
                #game-wrapper{\
                    display: block;\
                    margin: 0 auto;\
                }\
                iframe{\
                    border: none;\
                    display: block;\
                    background-color:#FFFFFF;\
                }\
                "}</style>  
            </div>
        );
    }
};

export default Contact;