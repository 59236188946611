
import React from 'react';
import $ from 'jquery';
//import axios from 'axios';
import './index.css';

function About() {

    /*useEffect(()=>{
        axios.get('http://44.242.148.51:3001/api/getStudents').then((response) => {
            var stringCount = numberWithCommas(response.data.count);
            document.getElementById("youthCounter").innerHTML = stringCount;
            //console.log(stringCount);
        }, [])
    })

    useEffect(()=>{
        axios.get('http://44.242.148.51:3001/api/getScholarships').then((response) => {
            document.getElementById("scholarshipCounter").innerHTML = "$"+Math.floor(response.data.discount/1000)+",000";
            //console.log(response.data.discount);
        }, [])
    })*/
    $('#AboutUsContent').ready(function() {
        $("#AboutUsContent").scroll(function(){
            if ($('#AboutUsContent').scrollTop() > 1000){
                document.getElementById("arrow").style.display = 'none';
            }
            $('.fade-in-section').each( function() {
                var bottom_of_object = $(this).position().top + $(this).outerHeight();
                var bottom_of_window = $('#AboutUsContent').scrollTop() + $('#AboutUsContent').height();
                if (bottom_of_window > bottom_of_object){
                    $(this).animate({'opacity':'1'}, 1500);
                }
            })
        })
        var videoHeight = $('.sizzleReel').width()*0.5625+"px";
        $('.sizzleReel').height(videoHeight);
        document.getElementById("AboutUsContent").scrollTop = 0;

    })

    return (
        <div id='AboutUsContent'>
            <div id="arrow"><img id="arrowImg" src='./imgs/Badges/downArrow.png'/></div>
            <div id='whoRweBox'>
                <div id='whoRweBadges'>
                    <div id='container'><img class='badges' alt="Byte Camp was founded in 2003" src='./imgs/Badges/Founded.png'/></div>
                    <div id='container'><img class='badges' alt='How many youth we have inspired' src='./imgs/Badges/Served.png'/><div id='youthCounter'>34,272</div></div>
                    <div id='container'><img class='badges' alt='How many grants and scholarships we have awarded' src='./imgs/Badges/Awarded.png'/><div id='scholarshipCounter'>$237,500</div></div>
                </div>
                <div id='whoRweText'>
                    <p class='title'>We Love to Teach</p>
                    <p id="whoRweBlurb" class='text' style={{marginBottom:'0px'}}>At Byte Camp, we make creative technology education fun for kids! With 20 years of experience offering quality programs in 3D Modelling, Animation, Coding, Music Production, Video Editing, and more, our after school and week long summer break camps are designed specifically for learners ages 9-14, providing a strong foundation for kids to apply their newfound skills wherever their imagination takes them.</p>
                    <p class='title' id="creativeTech">... Creative Tech!</p>
                </div>
                <div id='staff' class='fade-in-section'>
                    <div class="sizzleReel"><iframe loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/0bzJ11bi3Ko" title="Sizzle Reel 2022" ></iframe></div>
                </div>
                <div id='mission' class='fade-in-section'>
                    <p id='missionStatement'> <p style={{textAlign:'center'}}>Our mission is:</p>
                    1. To teach children and youth how to become productive and creative users of computers and other technologies.
                    <br/><br/>2. To teach children and youth the basic concepts behind how computers and other technologies work.
                    <br/><br/>3. To excite children and promote the value in using computers and other technologies to assist in their own productive and creative endeavours.
                    <br/><br/>4. To teach new literacy skills in digital media and communication technology
                    <br/><br/>5. To promote a healthy life balance of physical activity with time on the computer and other technology
                    <br/><br/>6. To provide these services equally to all youth regardless of income level or community profile
                    <br/><br/><br/><b>Registered Charity Number: 877212407RR0001</b>
                    </p>
                </div>
                <button onClick={changePage} class='fade-in-section' id='contactButton'>Contact Us</button>
            </div>
        </div>
    );
    
};

/*function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}*/

function changePage() {
    window.location.hash = '#co';
}

export default About;