
import React from 'react';
import './birthday.css';

var width = window.innerWidth;
var height = window.innerHeight;

class Birthday extends React.Component{

    componentDidMount(){

        if (width < height){ //mobile
            document.getElementById("bdayCard").style.width = width+"px";
            document.getElementById("bdayCard").style.left = -width/4+"px";

            var elements = document.getElementsByClassName("marker"); 
            for (var i = 0; i < elements.length; i++) {
                var element = elements[i];
                element.style.fontSize = width*0.031+"px";
            }
            document.getElementById("when").style.top = (width/2750*2062)*0.553+"px";
            document.getElementById("when").style.left = (width/2750)*430+"px";

            document.getElementById("where").style.top = (width/2750*2062)*0.624+"px";
            document.getElementById("where").style.left =(width/2750)*430+"px";

            document.getElementById("RSVP").style.top = (width/2750*2062)*0.71+"px";
            document.getElementById("RSVP").style.left = (width/2750)*700+"px";

        } else { //pc
            document.getElementById("bdayCard").style.height = height*0.75+"px";
            document.getElementById("bdayCard").style.left = 25+"px";

            var elements = document.getElementsByClassName("marker"); 
            for (var i = 0; i < elements.length; i++) {
                var element = elements[i];
                element.style.fontSize = height*0.031+"px";
              }
            document.getElementById("when").style.bottom = height*0.082+"px";
            document.getElementById("when").style.left = (height*0.75/2062)*1200+"px";

            document.getElementById("where").style.bottom = height*0.027+"px";
            document.getElementById("where").style.left =(height*0.75/2062)*1200+"px";

            document.getElementById("RSVP").style.bottom = height*-0.034+"px";
            document.getElementById("RSVP").style.left = (height*0.75/2062)*1400+"px";

        }
        
    }

    

    

    render() {
        return (
            <div id='BirthdayContent'>
                <img id="bdayCard" alt='Happy 20th Birthday Byte Camp' style={{position:'absolute', zIndex:1}} src="./calendar/bday_card.png"/>
                <div id='when' class="card" style={{position:'absolute', zIndex:3}}><a href="#r" id="whenButton" class="marker">Spring & Summer</a></div>
                <div id='where' class="card" style={{position:'absolute', zIndex:3}}><a href="#r" id="whereButton" class="marker">Locations near you</a></div>
                <div id='RSVP' class="card" style={{position:'absolute', zIndex:3}}><a href="mailto:alix@bytecamp.ca?&subject=Can't%20wait%20to%20see%20your%20summer%20schedule!&body=Hi%20Alix,%0D%0A%0D%0ACongratulations%20on%2020%20years%20of%20Byte%20Camp!😊%20Can%20you%20please%20add%20this%20email%20address%20to%20your%20sneak%20peek%20calendar%20mail%20out%20in%20late%20February?%20And%20a%20reminder%20for%20registrations%20when%20they%20open%20on%20March%201st.%0D%0A%0D%0AThank%20you!%0D%0A%0D%0A" id="rsvpButton" class="marker">HERE</a></div>
                <style>{"\
                    .marker{\
                        color: #000000;\
                        font-family: 'permanent_markerregular';\
                        font-size: 28px;\
                        cursor: pointer;\
                    }\
                "}</style>  
            </div>
        );
    }
};

export default Birthday;