import React from 'react';
import Matter from 'matter-js';
import $ from 'jquery';
import {TweenMax} from 'gsap';
import './index.css';
import characterInfo from './components/characterInfo.js';
import { isMobile } from 'react-device-detect';
import Register from '../Register/index';
import About from '../About/index';
import Contact from '../Contact/index';
import Learn from '../Learning/index';
import Projects from '../Projects/index';
import Birthday from '../Birthday/birthday';
//import { withRouter } from 'react-router';

var render,runner,mouse;
var hacksTried = "";
var characters = [];
var balls = [];
var logoBall;
var globalAngle = 0;
var ballColours = ['#ffe2ceff', '#ee3935ff', '#c5f43dff', '#1e79a8ff', '#ffff00ff', '#61beffff'];

//console.log(window.innerHeight);
//console.log(window.innerWidth);

var radius, largeRadius;
var circleSize = 17;

if (isMobile || (window.innerHeight > window.innerWidth)){
    if (window.innerHeight > window.innerWidth){
        radius = Math.round(window.innerHeight*circleSize/100/2);
        largeRadius = Math.round(window.innerHeight*15/100/2);
    } else {
        radius = Math.round(window.innerWidth*circleSize/100/2);
        largeRadius = Math.round(window.innerHeight*30/100/2);
    }
} else {
    radius = Math.round(window.innerWidth*circleSize/100/2);
    largeRadius = Math.round(window.innerHeight*30/100/2);
}

var startingRadius = radius;
var startingHeight = window.innerHeight;
var startingWidth = window.innerWidth;

console.log("Height:",window.innerHeight," Width:",window.innerWidth);

var characterWidth = largeRadius*0.5;
if (isMobile || (window.innerHeight > window.innerWidth)){
    if (window.innerHeight > window.innerWidth){
        characterWidth = largeRadius*0.4;
    }
}
// Create collision masks
var defaultCategory = 0x0001,
hiddenCategory = 0x0002,
wallCategory = 0x0004,
logoCategory = 0x0008,
ballCategory = 0x0016,
signCategory = 0x0032;

// Matter.js module aliases
var Engine = Matter.Engine,
    Render = Matter.Render,
    Runner = Matter.Runner,
    World = Matter.World,
    Body = Matter.Body,
    Bodies = Matter.Bodies,
    Composite = Matter.Composite,
    Constraint = Matter.Constraint,
    Vector = Matter.Vector,
    MouseConstraint = Matter.MouseConstraint,
    Mouse = Matter.Mouse;

class Main extends React.Component{

    constructor(props) {
        super(props);
        this.scene = React.createRef();
        this.canvas = React.createRef();
        this.state = {
            width: window.innerWidth, 
            height: window.innerHeight,
            diameter: radius*2,
            largeDiameter: largeRadius*2
        };
        this.mainEngine = null;
        this.mainWorld = null;
        this.confettiEngine = null;
        this.confettiWorld = null;
    }

    componentDidMount() {

        // create a Matter.js engine
        this.mainEngine = Engine.create(document.body, {timing: {timeScale:.2}});
        this.mainWorld = this.mainEngine.world;
        var width = this.state.width;
        var height = this.state.height;

        render = Render.create({
            element: this.scene.current,
            canvas: this.canvas.current,
            engine: this.mainEngine,
            hasBounds: true,
            options: {
                height:this.state.height,
                width: this.state.width,
                showAngleIndicator: false,
                background: 'transparent',
                wireframes: false,
                showSleeping: false
            }
        });

        // add mouse control
        mouse = Mouse.create(render.canvas);
        var mouseConstraint = MouseConstraint.create(this.mainEngine, {
            mouse: mouse,
            constraint: {
                stiffness: 0.9,
                render: {
                    visible: false
                }
            }
        });

        Render.setPixelRatio(render, 'auto');
        mouse.pixelRatio = devicePixelRatio;
        console.log(devicePixelRatio);
        Render.run(render);

        // create runner
        runner = Runner.create();
        Runner.run(runner, this.mainEngine);
        //runner.fps = 50;

        World.add(this.mainWorld, mouseConstraint);

        // add mask so things collide properly
        var wallOptions = {
            isStatic: true,
            collisionFilter:{
                category: wallCategory,
                mask: wallCategory | hiddenCategory | ballCategory
            }
        }

        // floor
        var ceiling = Bodies.rectangle(this.state.width/2, -this.state.height*0.3, this.state.width*2.5, 50, wallOptions);
        var floor = Bodies.rectangle(this.state.width/2, this.state.height*1.3, this.state.width*2.5, 50, wallOptions);
        var left = Bodies.rectangle(-this.state.width*0.3, this.state.height/2, 50, this.state.height*2.5, wallOptions);
        var right = Bodies.rectangle(this.state.width*1.3, this.state.height/2, 50, this.state.height*2.5, wallOptions);

        World.add(this.mainWorld, [
            ceiling,floor,left,right
        ]);

        var _pagesettings = [
            {
                // Home screen with logo and buttons
                boxes: [ 
                    [95,2],
                    [circleSize,circleSize],
                    [circleSize*1.2,circleSize*1.2],
                    [circleSize,circleSize],
                    [95,2],
                    [1,95],
                    [1,95]
                ],
                position: [
                    [50,2],
                    [20,70],
                    [50,70],
                    [80,70],
                    [50,98],
                    [2,50],
                    [98,50]
                ]
            },
            {
                //Find out More AKA About Us page
                boxes: [
                    [95,95],
                    [1,95],
                    [1,95],
                    [95,2],
                    [95,2],
                ],
                position: [
                    [50,50],
                    [2,50],
                    [98,50],
                    [50,2],
                    [50,98]
                ]
            },
            {
                //Camps page (once set up)
                boxes: [
                    [98,98],
                    [1,95],
                    [1,95],
                    [95,2],
                    [95,2],
                ],
                position: [
                    [50,50],
                    [2,50],
                    [98,50],
                    [50,2],
                    [50,98]
                ]
            },
            {
                //Register now page
                boxes: [
                    [95,95],
                    [1,95],
                    [1,95],
                    [95,2],
                    [95,2],
                ],
                position: [
                    [50,50],
                    [2,50],
                    [98,50],
                    [50,2],
                    [50,98]
                ]
            },
            {
                //Online learning page
                boxes: [
                    [95,95]
                ],
                position: [
                    [50,50]
                ]

            },
            {
                //Contact us page
                boxes: [
                    [95,95]
                ],
                position: [
                    [50,50]
                ]

            }
            
        ]

        function checkTallPage(){
            if (isMobile || (window.innerHeight > window.innerWidth)){
                if (window.innerHeight > window.innerWidth){
                    _pagesettings[0].position[1] = [50,40];
                    _pagesettings[0].position[2] = [50,60];
                    _pagesettings[0].position[3] = [50,80];
                } else {
                    _pagesettings[0].position[1] = [20,70];
                    _pagesettings[0].position[2] = [50,70];
                    _pagesettings[0].position[3] = [80,70];
                }
            } else {
                _pagesettings[0].position[1] = [20,70];
                _pagesettings[0].position[2] = [50,70];
                _pagesettings[0].position[3] = [80,70];
            }
        }

        checkTallPage();

        var blockOptions = {
            frictionAir: 0.2,
            collisionFilter: {
                category: defaultCategory,
                mask: defaultCategory | hiddenCategory //| logoCategory //| ballCategory
            }
        }

        // create buttons and pages
        var pages = [];
        for (var i = 0; i< _pagesettings.length; i++) {
            var b = [];
            for(var bi = 0; bi < _pagesettings[i].boxes.length;bi++) {
                var s =  _pagesettings[i].boxes[bi];
                var p = _pagesettings[i].position[bi];
                s[0] = Math.round((s[0]*width)/100);
                s[1] = Math.round((s[1]*height)/100);
                if (i === 0 && (bi > 0 && bi < 4)){
                    if(s[0] > s[1]){
                        s[1] = s[0];
                    } else {
                        s[0] = s[1];
                    }
                    b.push(Bodies.circle(Math.round(Math.random()*width*2)-width*(1-p[0]/100),Math.round(Math.random()*height*2)-height*(1-p[1]/100),s[0]/2,blockOptions));
                } else if (i === 0 && (bi === 0 || bi >= 4)) {
                    b.push(Bodies.rectangle(Math.round(Math.random()*width*2)-width*(1-p[0]/100),Math.round(Math.random()*height*2)-height*(1-p[1]/100),s[0],s[1],{frictionAir:0.2, collisionFilter:{category:logoCategory,mask:logoCategory | defaultCategory}}));
                } else if ((i === 3 || i=== 2 || i=== 1) && bi >= 1) {
                    b.push(Bodies.rectangle(Math.round(width*2)-width*(1-p[0]/100),Math.round(height*2)-height*(1-p[1]/100),s[0],s[1],{frictionAir:0.2, collisionFilter:{category:logoCategory,mask:logoCategory | defaultCategory}}));
                } else {
                    if (window.innerWidth > 1050 && i === 1){
                        b.push(Bodies.rectangle(Math.round(width*2)-width*(1-p[0]/100),Math.round(height*2)-height*(1-p[1]/100),900,s[1],blockOptions));
                    } else if (window.innerWidth > 1050 && (i === 3 || i === 2)){
                        b.push(Bodies.rectangle(Math.round(width*2)-width*(1-p[0]/100),Math.round(height*2)-height*(1-p[1]/100),1000,s[1],blockOptions));
                    } else {
                        b.push(Bodies.rectangle(Math.round(width*2)-width*(1-p[0]/100),Math.round(height*2)-height*(1-p[1]/100),s[0],s[1],blockOptions));
                    }
                }
            }
            _pagesettings[i].composite = Composite.create({bodies:b});
            _pagesettings[i].bodies = b;
            pages.push(_pagesettings[i].composite);
        }
        // add all of the bodies to the world
        World.add(this.mainWorld, pages);

        this.mainWorld.gravity.x = 0;
        this.mainWorld.gravity.y = 0;

        var characterOptions = {
            density: 0.4,
            restitution: 0.6,
            friction: 0.01,
            frictionAir: 0,
            collisionFilter:{
                category: hiddenCategory,
                mask: wallCategory | defaultCategory | ballCategory
            }
        }

        let randomNumbers = [];
        while (randomNumbers.length < 20) {
            let randomNum = Math.floor(Math.random() * characterInfo.length);
            if (!randomNumbers.includes(randomNum)) {
            randomNumbers.push(randomNum);
            }
        }

        //console.log(characterWidth);
        var imgs = [];
        var dimensions = [];
        var sizes = [];
        for(i = 0; i < 20; i++){
            characters[i] = Bodies.circle(Math.random()*width,Math.random()*height, characterWidth*characterInfo[randomNumbers[i]].size[0], characterOptions);
            imgs[i] = characterInfo[randomNumbers[i]].img[0];
            sizes[i] = characterInfo[randomNumbers[i]].size[0];
            //console.log(imgs[i]);
            if (characterInfo[randomNumbers[i]].dimensions[0][0] < characterInfo[randomNumbers[i]].dimensions[0][1]){
                dimensions[i] = characterInfo[randomNumbers[i]].dimensions[0][1];
            } else {
                dimensions[i] = characterInfo[randomNumbers[i]].dimensions[0][0];
            }
            Body.setVelocity(characters[i], {x:Math.random()*4-2, y:Math.random()*4-2});
        }

        var ballOptions = {
            density: 0.4,
            restitution: 0.6,
            friction: 0.01,
            frictionAir: 0,
            gravity: {x:0, y:0},
            collisionFilter:{
                category: ballCategory,
                mask: wallCategory | defaultCategory | hiddenCategory
            }
        }

        for(i = 0; i < 25; i++){
            balls[i] = Bodies.circle(Math.random()*width,Math.random()*height, characterWidth, ballOptions);
            Body.setVelocity(balls[i], {x:Math.random()*4-2, y:Math.random()*4-2});
            balls[i].render.fillStyle = ballColours[i%6];
            balls[i].render.opacity = 0.8;
        }

        
        // add balls and characters to the world
        World.add(this.mainWorld, balls);
        World.add(this.mainWorld, characters);
        
        var logoPos = [0,0];
        if (isMobile || (window.innerHeight > window.innerWidth)){
            if (window.innerHeight > window.innerWidth) {
                logoPos[0] = width*50/100;
                logoPos[1] = height*15/100;
            } else {
                logoPos[0] = width*50/100;
                logoPos[1] = height*20/100;
            }
        } else {
            logoPos[0] = width*50/100;
            logoPos[1] = height*20/100;
        }

        var logoBallOptions = { 
            density: 0.004, 
            render: {
                sprite: {
                    //texture: "./calendar/byte_logo.png",
                    texture: "./calendar/bday_logo.png",
                    xScale: largeRadius/334*2,
                    yScale: largeRadius/334*2
                }
            },
            isSensor: true
        };
        logoBall = Bodies.circle(logoPos[0], logoPos[1], largeRadius, logoBallOptions);
        var anchor = { x: logoPos[0], y: logoPos[1] },
            elastic = Constraint.create({
                pointA: anchor,
                bodyB: logoBall,
                stiffness: 0.2,
                render: {
                    visible: false
                }
            });

        Composite.add(this.mainWorld, [logoBall, elastic]);

        // Initialize the confetti Matter.js world
        this.confettiEngine = Engine.create();
        this.confettiWorld = this.confettiEngine.world;

        // Create a Render instance for the confetti world
        const confettiRender = Render.create({
            element: document.getElementById('confetti-container'),
            engine: this.confettiEngine,
            options: {
                width: width,
                height: height,
                background: "transparent",
                wireframes: false,
                pixelRatio: window.devicePixelRatio
               
              }
        });

        this.confettiWorld.gravity.y = 0.5;
  
        // Start the confetti render
        Render.run(confettiRender);

        // create runner
        var confettiRunner = Runner.create();
        Runner.run(confettiRunner, this.confettiEngine);

        var defaultConfWorldCategory = 0x0001,
            confettiCategory = 0x0002;
		
        const confettiColors = ['#a864fd','#fd2ad3','#51b2e8','#ff9900','#ffff00'];

        const createShape = function(x, y) {
            return Bodies.rectangle(x, y, 5, 10, {
                density: 0.9,
                frictionAir: 0.05,
                restitution: 0,
                isStatic: false,
                render: {
                    fillStyle: confettiColors[Math.floor(Math.random()*confettiColors.length)],
                    sprite: {
                        yScale: 1,
                        xScale: 1, 
                    }
                },
                collisionFilter: {
                    category: confettiCategory,
                    mask: defaultConfWorldCategory | confettiCategory
                }
            });
        }


        //Create a Static Element
        /*const bigBall = Bodies.circle(width / 2, height / 3*2, Math.min(width / 6, height /6), {
            isStatic: true, 
            render: {
            fillStyle: "black",
            visible: true
            },
            collisionFilter: {
                category: defaultConfWorldCategory,
                mask: confettiCategory
            }

        });

        const leftBall = Bodies.circle(width / 4, height / 3*2, Math.min(width / 6, height /6), {
        isStatic: true, 
        render: {
            fillStyle: "black",
            visible: true
        },
            collisionFilter: {
                category: defaultConfWorldCategory,
                mask: confettiCategory
            }

        });

        const rightBall = Bodies.circle(width / 4*3, height / 3*2, Math.min(width / 6, height /6), {
            isStatic: true, 
            render: {
                fillStyle: "black",
                visible: true
            },
            collisionFilter: {
                category: defaultConfWorldCategory,
                mask: confettiCategory
            }
        }); 

        World.add(this.confettiWorld, [bigBall,leftBall,rightBall]); */

        // Create a wall for the shapes to bounce off
        const confWallOptions = {
            isStatic: true,
            render: {
                visible: true
            },
            collisionFilter: {
                category: defaultConfWorldCategory,
                mask: confettiCategory
            }
        }

        const confGround = Bodies.rectangle(width / 2, height + 150, width + 100, 100, confWallOptions)
        const confCeiling = Bodies.rectangle(width / 2, -50, width + 100, 100, confWallOptions)
        const confLeftWall = Bodies.rectangle(-50, height / 2, 100, height + 100, confWallOptions)
        const confRightWall = Bodies.rectangle(width + 50, height / 2, 100, height + 100, confWallOptions)

        World.add(this.confettiWorld, [confGround, confCeiling, confLeftWall, confRightWall]);

        var sign = Bodies.rectangle(width/2, -height*0.7, width*0.3, height*0.6, {
            collisionFilter: {
                category : signCategory,
                mask: 0x0000
            },
            render: {
                visible: false
            },
        });

        var constraint1 = Constraint.create({
            pointA: { x: width/4*3, y: -height-100 },
            bodyB: sign,
            pointB: { x: width/3, y: -height*0.7/2.2 },
            length: height*0.3,
            stiffness: 0.006
        });
        var constraint2 = Constraint.create({
            pointA: { x: width/4, y: -height-100 },
            bodyB: sign,
            pointB: { x: -width/3, y: -height*0.7/2.2 },
            length: height*0.3,
            stiffness: 0.006
        });
        
        World.add(this.confettiWorld, [sign,constraint1,constraint2]);

        function resetLogo() {
            
            // Gradually decrease the angular velocity to smoothly return to 0
            var currentAngularVelocity = logoBall.angularVelocity;
            var newAngularVelocity = Math.max(currentAngularVelocity - 0.0001, 0); // Adjust the decrement as needed
            Matter.Body.setAngularVelocity(logoBall, newAngularVelocity);

            var logoRotation = logoBall.angle * (180 / Math.PI);
    
            // Check if the angular velocity is close to 0
            if (logoRotation % 360 <= 1) {
                // Reset the angular position to 0
                Matter.Body.setAngle(logoBall, 0);
                Matter.Body.setAngularVelocity(logoBall, 0);
            } else {
                // Continue the animation
                requestAnimationFrame(resetLogo);
            }
            
        }
        
        var timer = 0;
        const startTime = 20;
        const endTime = 70;
        var signDown = false;

        function dropSign(time){
            timer++;
            //console.log(timer);
            if (time === 0) {
                timer = time;
            }
            if (timer < startTime+5){
                requestAnimationFrame(dropSign);
            }
            else if (timer < endTime){
                constraint1.pointA = {x:width/4*3, y:-height-100+height*(timer-25)/(endTime-startTime-5)};
                constraint2.pointA = {x:width/4, y:-height-100+height*(timer-25)/(endTime-startTime-5)};
                requestAnimationFrame(dropSign);
            } else {
                //console.log(constraint1.pointA.y, constraint2.pointA.y);
                signDown = true;
                resetLogo();
            }
        }
        
        function hideSign(time){
            timer++;
            if (time === 0){
                timer = time;
            }
            if (timer < 70){
                constraint1.pointA = {x:width/4*3, y:-100-height*timer/70};
                constraint2.pointA = {x:width/4, y:-100-height*timer/70};
                requestAnimationFrame(hideSign);
            } else {
                constraint1.pointA = {x:width/4*3, y:-height-100};
                constraint2.pointA = {x:width/4, y:-height-100};
            }
        }

        var confettiTimer = 0;
        const startConfetti = (time) => {
            // Create confetti particles
            if (time === 0){
                confettiTimer = 0;
                World.clear(this.confettiWorld, false);
                World.add(this.confettiWorld, [sign,constraint1,constraint2]);
            }
            confettiTimer++;
            console.log(confettiTimer);
            var confetti = [];
            for (var i = 0; i < 10; i++){
              const shape = createShape(width/2-50,100);
              confetti.push(shape);
              World.add(this.confettiWorld, shape);
              Body.setAngularVelocity(shape, 30);
            }
            for (i = 0; i < 10; i++){
              const shape = createShape(width/2+50,100);
              confetti.push(shape);
              World.add(this.confettiWorld, shape);
              Body.setAngularVelocity(shape, -30);
            }
            if (confettiTimer < 20){
                Body.setAngularVelocity(logoBall, 0.6);
                requestAnimationFrame(() => startConfetti(confettiTimer));
            } else {
                console.log("dropping sign");
                dropSign(0);
            }
        }

        /*Events.on(engine, 'afterUpdate', function() {
            if (mouseConstraint.mouse.button === -1 && (logoBall.position.x > 190 || logoBall.position.y < 430)) {
                rock = Bodies.polygon(170, 450, 7, 20, logoBallOptions);
                Composite.add(engine.world, logoBall);
                elastic.bodyB = logoBall;
            }
        });*/

        var currentPage;
        const pageHistory = [];
        var numOfPages = 0;
        
        $('.menu-content a').each(function (i) {
            var page = _pagesettings[i];
            $(this).click(function () {
                focusObject(page, i);
            });

        });

        for (i = 0; i < 6; i++){
            pages[i].bodies[0].render.visible = false;
            if (i === 0){
                for (var j = 1; j < 7; j++){
                    pages[i].bodies[j].render.visible = false;
                }
            }
            if (i === 3 || i === 1 || i === 2){
                for (j = 1; j < 5; j++){
                    pages[i].bodies[j].render.visible = false;
                }
            }
        }
        
        // div for each button
        var register = document.querySelector("#register");
        var aboutus = document.querySelector("#aboutus");
        var learn = document.querySelector("#learn");
        // div for each page
        var aboutusPage = document.querySelector("#aboutuspage");
        var projectsPage = document.querySelector("#projectspage");
        var registerPage = document.querySelector("#registerpage");
        var learningPage = document.querySelector("#learningpage");
        var contactusPage = document.querySelector("#contactuspage");
        var bdayPage = document.querySelector("#bdayPage")

        if(window.innerWidth > 1000){
            aboutusPage.style.width = '900px';
            registerPage.style.width = '1000px';
            projectsPage.style.width = '1000px';
        }

        var offset = 50;
        if (isMobile){
            offset = 50;
        }

        window.requestAnimationFrame(update);
        //focusObject(_pagesettings[0], 0);
        setHash();
        centerLogo();

        var showAllCharacters = false;
        var bCheckEnabled = true;
        var img;
        var imgNum = 0;
        var imgArray = [];

        function loadImage() {

            if (imgNum >= 24) {
                //console.log(`Loaded ${imgNum} image(s)!`);
                console.log('Loaded ' + imgNum + ' image(s)!)');
                showAllCharacters = true;
                return;
            }
            if (bCheckEnabled) {

                bCheckEnabled = false;
                img = new Image();
                img.onload = fExists();
                img.src = imgs[imgNum];
            }
        };

        function fExists() {
            imgArray.push(img);
            imgNum++;
            bCheckEnabled = true;
        }

        if (isMobile){
            //hide social media icons on page load
            document.getElementById("menu-social-media").classList.add("hide");

            var menuTimeout;

            function trackMousePos(event){
                console.log(`x:${event.clientX}, y:${event.clientY}`)
                //if top bar clicked show menu
                if (event.clientY < 30){
                    document.getElementById("menu").classList.add("show");
                    document.getElementById("menu-symbol").classList.add("hide");
                    document.getElementById("menu-social-media").classList.remove("hide");
                    //hide menu after 5 seconds if it hasn't already hidden
                    menuTimeout = setTimeout(()=>{
                        document.getElementById("menu").classList.remove("show");
                        document.getElementById("menu-social-media").classList.add("hide");
                        document.getElementById("menu-symbol").classList.remove("hide");
                    }, 5000)
                } else {
                    //if user clicks anywhere but on the top bar close menu if it is open
                    if (document.getElementById("menu").classList.contains("show")){
                        clearTimeout(menuTimeout);
                        document.getElementById("menu").classList.remove("show");
                        document.getElementById("menu-social-media").classList.add("hide");
                        document.getElementById("menu-symbol").classList.remove("hide");
                    }
                }

                // Check if mouse pos is within logo boundaries x:width*50/100-largeRadius/2 and y:height*15/100-largeRadius/2
                hash = window.location.hash;
                if (hash.length === 0 || hash === '#h'){
                    if (!signDown){
                        if (event.clientX > (width*50/100-largeRadius/2) && event.clientX < width*50/100+largeRadius/2 && event.clientY > height*15/100-largeRadius/2 && event.clientY < height*15/100+largeRadius/2){
                            console.log("Rain");
                            startConfetti(0);
                        }
                    } else {
                        if (signDown) {
                            timer = 0;
                            console.log("raise sign");
                            signDown = false;
                            hideSign(0);
                        }
                    }
                }
            }

            window.onmousedown = function(){
                if (document.getElementById("menu").classList.contains("show")){
                    clearTimeout(menuTimeout);
                    document.getElementById("menu").classList.remove("show");
                    document.getElementById("menu-social-media").classList.add("hide");
                    document.getElementById("menu-symbol").classList.remove("hide");
                }
            }

            document.addEventListener("click", trackMousePos);
        } else {

            function trackMousePos(event){
                console.log(`x:${event.clientX}, y:${event.clientY}`)
                hash = window.location.hash;
                if (hash.length === 0 || hash === '#h'){
                    // Check if mouse pos is within logo boundaries x:width*50/100-largeRadius/2 and y:height*15/100-largeRadius/2
                    if (!signDown){
                        if (event.clientX > (width*50/100-largeRadius/2) && event.clientX < width*50/100+largeRadius/2 && event.clientY > height*15/100-largeRadius/2 && event.clientY < height*15/100+largeRadius/2){
                            console.log("Rain");
                            startConfetti(0);
                        } 
                    } else {
                        if (signDown) {
                            timer = 0;
                            console.log("raise sign");
                            signDown = false;
                            hideSign(0);
                        }
                    }
                }
            }
            document.addEventListener("click", trackMousePos);
        }

        function focusObject (page, pagenum) {

            if(typeof currentPage === "object") {
                var consts = Composite.allConstraints(currentPage.composite);
                for (var i=0; i< consts.length; i++) {
                    Composite.removeConstraint(currentPage.composite,consts[i]);
                }
                var bodies = Composite.allBodies(currentPage.composite);
                for (i = 0; i <bodies.length; i++){
                    bodies[i].collisionFilter.mask = defaultCategory | logoCategory;
                }

            }
            bodies = page.bodies;
            var composite = page.composite;
            for (var bindex = 0; bindex < bodies.length; bindex++) {
                var body = bodies[bindex];
                body.collisionFilter.mask = defaultCategory | hiddenCategory | logoCategory;
                var bsize = page.boxes[bindex];
                var bpos = page.position[bindex].slice();
                bpos[0] = Math.round((bpos[0]*width)/100);
                bpos[1] = Math.round((bpos[1]*height)/100);
                var pos = {
                    x1: bpos[0] - bsize[0]/2,
                    x2: bpos[0] + bsize[0]/2,
                    y1: bpos[1] - bsize[1]/2,
                    y2: bpos[1] + bsize[1]/2
                }
                Composite.addConstraint(composite,Constraint.create({
                    pointA : {x:pos.x1,y:pos.y1},
                    bodyB : body,
                    pointB : Vector.rotate({x:-bsize[0]/2,y:-bsize[1]/2},body.angle),
                    render: {visible: false, strokeStyle: "black"},
                    stiffness:.1
                }));
                Composite.addConstraint(composite,Constraint.create({
                    pointA : {x:pos.x2,y:pos.y2},
                    bodyB : body,
                    pointB : Vector.rotate({x:bsize[0]/2,y:bsize[1]/2},body.angle),
                    render: {visible: false, strokeStyle: "black"},
                    stiffness:.1
                }));
            }
            pageHistory[numOfPages] = [page,pagenum];
            numOfPages++;
            currentPage = page;

            TweenMax.to(composite.constraints,1,{length:0,delay:0});

            if (pagenum === 0){
                if (document.getElementById("menu").classList.contains("show")){
                    document.getElementById("menu").classList.remove("show");
                    document.getElementById("menu-social-media").classList.add("hide");
                    document.getElementById("menu-symbol").classList.remove("hide");
                }
            }


        }
        function refocusObject (page, pagenum) {

            if(typeof currentPage === "object") {
                var consts = Composite.allConstraints(currentPage.composite);
                for (var i=0; i< consts.length; i++) {
                    Composite.removeConstraint(currentPage.composite,consts[i]);
                }
                var bodies = Composite.allBodies(currentPage.composite);
                for (i = 0; i <bodies.length; i++){
                    bodies[i].collisionFilter.mask = defaultCategory | logoCategory;
                }

            }
            bodies = page.bodies;
            var composite = page.composite;
            for (var bindex = 0; bindex < bodies.length; bindex++) {
                var body = bodies[bindex];
                body.collisionFilter.mask = defaultCategory | hiddenCategory | logoCategory;
                var bsize = page.boxes[bindex];
                var bpos = page.position[bindex].slice();
                bpos[0] = Math.round((bpos[0]*width)/100);
                bpos[1] = Math.round((bpos[1]*height)/100);
                var pos = {
                    x1: bpos[0] - bsize[0]/2,
                    x2: bpos[0] + bsize[0]/2,
                    y1: bpos[1] - bsize[1]/2,
                    y2: bpos[1] + bsize[1]/2
                }
                Composite.addConstraint(composite,Constraint.create({
                    pointA : {x:pos.x1,y:pos.y1},
                    bodyB : body,
                    pointB : Vector.rotate({x:-bsize[0]/2,y:-bsize[1]/2},body.angle),
                    render: {visible: false, strokeStyle: "black"},
                    stiffness:.1
                }));
                Composite.addConstraint(composite,Constraint.create({
                    pointA : {x:pos.x2,y:pos.y2},
                    bodyB : body,
                    pointB : Vector.rotate({x:bsize[0]/2,y:bsize[1]/2},body.angle),
                    render: {visible: false, strokeStyle: "black"},
                    stiffness:.1
                }))
            }

            currentPage = page;
            if (pagenum === 0){
                if (document.getElementById("menu").classList.contains("show")){
                    document.getElementById("menu").classList.remove("show");
                    document.getElementById("menu-social-media").classList.add("hide");
                    document.getElementById("menu-symbol").classList.remove("hide");
                }
            } 

            TweenMax.to(composite.constraints,1,{length:0,delay:0});
        }

        var popcornSize = [0, 0.35, 0.7, 1.05, 1.4, 1.3, 1.2, 1.1, 1, 0.9, 0.8, 0.857, 0.914, 0.971, 1.028, 1.085, 1.142, 1.2, 1.136, 1.072, 1.008, 0.944, 0.88, 0.93, 0.98, 1.03, 1.08, 1.04, 1, 0.96, 1, 1.04]
        var startPop = [25,37,40,50,57,60,63,65,68,72,75,79,84,87,91,96,101,110,125,135]
        var counter = 0;
        var k = 0;

        // Can this be included in the update function?
        function popcorn() {
            if (showAllCharacters === true){
                //showAllCharacters = false;
                counter += 1;
                for (k = 0; k < 20; k++){
                    if ((counter-startPop[k]) === 0){
                        characters[k].render.sprite.texture = imgs[k];
                        characters[k].render.sprite.xScale = 0;
                        characters[k].render.sprite.yScale = 0;
                    }
                    if ((counter-startPop[k]) > 0 && (counter-startPop[k]) < popcornSize.length){
                        characters[k].render.sprite.xScale = (characterWidth*sizes[k])*2/dimensions[k]*popcornSize[(counter-startPop[k])];
                        //console.log(characters[k].render.sprite.xScale);
                        characters[k].render.sprite.yScale = (characterWidth*sizes[k])*2/dimensions[k]*popcornSize[(counter-startPop[k])];
                    } else if ((counter-startPop[k]) >= popcornSize.length){
                        characters[k].render.sprite.xScale = (characterWidth*sizes[k])*2/dimensions[k];
                        characters[k].render.sprite.yScale = (characterWidth*sizes[k])*2/dimensions[k];
                    }
                }
                if (counter > startPop[startPop.length]+popcornSize.length ){
                    showAllCharacters = "DONE";
                    //console.log("All characters visible");
                } 
            } else  if (showAllCharacters === false){
                loadImage()
            }
            
        }

        var hash;

        function update() {

            popcorn()

            //logoBall.angle = globalAngle;

            bdayPage.style.transform = "translate("
            + (sign.position.x-width/4)
            + "px, "
            + (sign.position.y-height*0.3)
            + "px )";
            bdayPage.style.transform += "rotate( " + sign.angle + "rad )";
          
            aboutus.style.transform = "translate("
            + (pages[0].bodies[1].position.x-radius)
            + "px, "
            + (pages[0].bodies[1].position.y-radius)
            + "px )";
            aboutus.style.transform += "rotate( " + pages[0].bodies[1].angle + "rad )";

            register.style.transform = "translate("
            + (pages[0].bodies[2].position.x-radius*1.2) 
            + "px, "
            + (pages[0].bodies[2].position.y-radius*1.2)
            + "px )";
            register.style.transform += "rotate( " + pages[0].bodies[2].angle + "rad )";

            learn.style.transform = "translate("
            + (pages[0].bodies[3].position.x-radius) 
            + "px, "
            + (pages[0].bodies[3].position.y-radius)
            + "px )";
            learn.style.transform += "rotate( " + pages[0].bodies[3].angle + "rad )";

            // Depending on the page get rid of any border walls that shouldn't be there to prevent excess jostling
            if (window.innerWidth > 1050){
                hash = window.location.hash;
                if (hash.length === 0 || hash === '#h'){
                    for (var i = 1; i < 6; i++){
                        if (i === 1 || i === 2 || i === 3){
                            for (var j = 1; j < 5; j++){
                                if ((pages[i].bodies[j].position.x > 0 && pages[i].bodies[j].position.x < window.innerWidth) || (pages[i].bodies[j].position.y > 0 && pages[i].bodies[j].position.y < window.innerHeight)){
                                    Body.setPosition(pages[i].bodies[j],{x:-1000,y:-1000})
                                }
                            }
                        }
                    }
                } else {
                    for (j = 0; j < 7; j++){
                        if (j === 0 || j > 3){
                            if ((pages[0].bodies[j].position.x > 0 && pages[0].bodies[j].position.x < window.innerWidth) || (pages[0].bodies[j].position.y > 0 && pages[0].bodies[j].position.y < window.innerHeight)){
                                Body.setPosition(pages[0].bodies[j],{x:-1000,y:-1000})
                            }
                        }
                    }   
                }
            }

            //If About Us page is centered stop wiggle else let page follow matter box
            if (window.innerWidth < 1050){
                if (pages[1].bodies[0].position.x > (Math.round((50*width)/100)-10) && pages[1].bodies[0].position.x < (Math.round((50*width)/100)+10) && pages[1].bodies[0].position.y > (Math.round((50*height)/100)-10) && pages[1].bodies[0].position.y < (Math.round((50*height)/100)+10)){
                    aboutusPage.style.transform = "translate("
                    + (Math.round((width*offset)/100)-(width*95/100/2)) 
                    + "px, "
                    + (Math.round((height*offset)/100)-(height*95/100/2))
                    + "px )";
                    aboutusPage.style.transform += "rotate( 0rad )";
                } else {
                    aboutusPage.style.transform = "translate("
                    + (pages[1].bodies[0].position.x-(width*95/100/2)) 
                    + "px, "
                    + (pages[1].bodies[0].position.y-(height*95/100/2))
                    + "px )";
                    aboutusPage.style.transform += "rotate( " + pages[1].bodies[0].angle + "rad )";
                }
            } else {
                if (pages[1].bodies[0].position.x > (Math.round((50*width)/100)-10) && pages[1].bodies[0].position.x < (Math.round((50*width)/100)+10) && pages[1].bodies[0].position.y > (Math.round((50*height)/100)-10) && pages[1].bodies[0].position.y < (Math.round((50*height)/100)+10)){
                    aboutusPage.style.transform = "translate("
                    + (Math.round((width*offset)/100)-(900/2)) 
                    + "px, "
                    + (Math.round((height*offset)/100)-(height*0.95/2))
                    + "px )";
                    aboutusPage.style.transform += "rotate( 0rad )";
                    if (pages[0].bodies[1].position.x > 0 && pages[0].bodies[1].position.x < window.innerWidth && pages[0].bodies[1].position.y > 0 && pages[0].bodies[1].position.y < window.innerHeight){
                        Body.setPosition(pages[0].bodies[1], {x:-500,y:-500});
                    }
                    if (pages[0].bodies[2].position.x > 0 && pages[0].bodies[2].position.x < window.innerWidth && pages[0].bodies[2].position.y > 0 && pages[0].bodies[2].position.y < window.innerHeight){
                        Body.setPosition(pages[0].bodies[2], {x:-500,y:-500});
                    }
                    if (pages[0].bodies[3].position.x > 0 && pages[0].bodies[3].position.x < window.innerWidth && pages[0].bodies[3].position.y > 0 && pages[0].bodies[3].position.y < window.innerHeight){
                        Body.setPosition(pages[0].bodies[3], {x:-500,y:-500});
                    }
                } else {
                    aboutusPage.style.transform = "translate("
                    + (pages[1].bodies[0].position.x-(900/2)) 
                    + "px, "
                    + (pages[1].bodies[0].position.y-(height*0.95/2))
                    + "px )";
                }
            }
            
            //If Camps page is centered stop wiggle else let page follow matter box
            if (window.innerWidth < 1050){
                if (pages[2].bodies[0].position.x > (Math.round((50*width)/100)-10) && pages[2].bodies[0].position.x < (Math.round((50*width)/100)+10) && pages[2].bodies[0].position.y > (Math.round((50*height)/100)-10) && pages[2].bodies[0].position.y < (Math.round((50*height)/100)+10)){
                    projectsPage.style.transform = "translate("
                    + (Math.round((width*offset)/100)-(width*95/100/2)) 
                    + "px, "
                    + (Math.round((height*offset)/100)-(height*95/100/2))
                    + "px )";
                    projectsPage.style.transform += "rotate( 0rad )";
                } else {
                    projectsPage.style.transform = "translate("
                    + (pages[2].bodies[0].position.x-(width*95/100/2)) 
                    + "px, "
                    + (pages[2].bodies[0].position.y-(height*95/100/2))
                    + "px )";
                    projectsPage.style.transform += "rotate( " + pages[2].bodies[0].angle + "rad )";
                }
            } else {
                if (pages[2].bodies[0].position.x > (Math.round((50*width)/100)-10) && pages[2].bodies[0].position.x < (Math.round((50*width)/100)+10) && pages[2].bodies[0].position.y > (Math.round((50*height)/100)-10) && pages[2].bodies[0].position.y < (Math.round((50*height)/100)+10)){
                    projectsPage.style.transform = "translate("
                    + (Math.round((width*offset)/100)-(1000/2)) 
                    + "px, "
                    + (Math.round((height*offset)/100)-(height*0.95/2))
                    + "px )";
                    projectsPage.style.transform += "rotate( 0rad )";
                    if (pages[0].bodies[1].position.x > 0 && pages[0].bodies[1].position.x < window.innerWidth && pages[0].bodies[1].position.y > 0 && pages[0].bodies[1].position.y < window.innerHeight){
                        Body.setPosition(pages[0].bodies[1], {x:-500,y:-500});
                    }
                    if (pages[0].bodies[2].position.x > 0 && pages[0].bodies[2].position.x < window.innerWidth && pages[0].bodies[2].position.y > 0 && pages[0].bodies[2].position.y < window.innerHeight){
                        Body.setPosition(pages[0].bodies[2], {x:-500,y:-500});
                    }
                    if (pages[0].bodies[3].position.x > 0 && pages[0].bodies[3].position.x < window.innerWidth && pages[0].bodies[3].position.y > 0 && pages[0].bodies[3].position.y < window.innerHeight){
                        Body.setPosition(pages[0].bodies[3], {x:-500,y:-500});
                    }
                } else {
                    projectsPage.style.transform = "translate("
                    + (pages[2].bodies[0].position.x-(1000/2)) 
                    + "px, "
                    + (pages[2].bodies[0].position.y-(height*0.95/2))
                    + "px )";
                }
            }

            //If Register page is centered stop wiggle else let page follow matter box
            if (window.innerWidth < 1050){
                if (pages[3].bodies[0].position.x > (Math.round((50*width)/100)-10) && pages[3].bodies[0].position.x < (Math.round((50*width)/100)+10) && pages[3].bodies[0].position.y > (Math.round((50*height)/100)-10) && pages[3].bodies[0].position.y < (Math.round((50*height)/100)+10)){
                    registerPage.style.transform = "translate("
                    + (Math.round((width*offset)/100)-(width*0.95/2)) 
                    + "px, "
                    + (Math.round((height*offset)/100)-(height*0.95/2))
                    + "px )";
                    registerPage.style.transform += "rotate( 0rad )";
                } else {
                    registerPage.style.transform = "translate("
                    + (pages[3].bodies[0].position.x-(width*0.95/2)) 
                    + "px, "
                    + (pages[3].bodies[0].position.y-(height*0.95/2))
                    + "px )";
                    registerPage.style.transform += "rotate( " + pages[3].bodies[0].angle + "rad )";
                }
            } else {
                if (pages[3].bodies[0].position.x > (Math.round((50*width)/100)-10) && pages[3].bodies[0].position.x < (Math.round((50*width)/100)+10) && pages[3].bodies[0].position.y > (Math.round((50*height)/100)-10) && pages[3].bodies[0].position.y < (Math.round((50*height)/100)+10)){
                    registerPage.style.transform = "translate("
                    + (Math.round((width*offset)/100)-(1000/2)) 
                    + "px, "
                    + (Math.round((height*offset)/100)-(height*0.95/2))
                    + "px )";
                    registerPage.style.transform += "rotate( 0rad )";
                    if (pages[0].bodies[1].position.x > 0 && pages[0].bodies[1].position.x < window.innerWidth && pages[0].bodies[1].position.y > 0 && pages[0].bodies[1].position.y < window.innerHeight){
                        Body.setPosition(pages[0].bodies[1], {x:-500,y:-500});
                    }
                    if (pages[0].bodies[2].position.x > 0 && pages[0].bodies[2].position.x < window.innerWidth && pages[0].bodies[2].position.y > 0 && pages[0].bodies[2].position.y < window.innerHeight){
                        Body.setPosition(pages[0].bodies[2], {x:-500,y:-500});
                    }
                    if (pages[0].bodies[3].position.x > 0 && pages[0].bodies[3].position.x < window.innerWidth && pages[0].bodies[3].position.y > 0 && pages[0].bodies[3].position.y < window.innerHeight){
                        Body.setPosition(pages[0].bodies[3], {x:-500,y:-500});
                    }
                } else {
                    registerPage.style.transform = "translate("
                    + (pages[3].bodies[0].position.x-(1000/2)) 
                    + "px, "
                    + (pages[3].bodies[0].position.y-(height*0.95/2))
                    + "px )";
                }
            }

            //If Online Learning page is centered stop wiggle else let page follow matter box
            if (pages[4].bodies[0].position.x > (Math.round((50*width)/100)-10) && pages[4].bodies[0].position.x < (Math.round((50*width)/100)+10) && pages[4].bodies[0].position.y > (Math.round((50*height)/100)-10) && pages[4].bodies[0].position.y < (Math.round((50*height)/100)+10)){
                learningPage.style.transform = "translate("
                + (Math.round((width*offset)/100)-(width*95/100/2)) 
                + "px, "
                + (Math.round((height*offset)/100)-(height*95/100/2))
                + "px )";
                learningPage.style.transform += "rotate( 0rad )";
            } else {
                learningPage.style.transform = "translate("
                + (pages[4].bodies[0].position.x-(width*95/100/2)) 
                + "px, "
                + (pages[4].bodies[0].position.y-(height*95/100/2))
                + "px )";
                learningPage.style.transform += "rotate( " + pages[4].bodies[0].angle + "rad )";
                
            }

            //If Contact Us page is centered stop wiggle else let page follow matter box
            if (pages[5].bodies[0].position.x > (Math.round((50*width)/100)-10) && pages[5].bodies[0].position.x < (Math.round((50*width)/100)+10) && pages[5].bodies[0].position.y > (Math.round((50*height)/100)-10) && pages[5].bodies[0].position.y < (Math.round((50*height)/100)+10)){
                contactusPage.style.transform = "translate("
                + (Math.round((width*offset)/100)-(width*95/100/2)) 
                + "px, "
                + (Math.round((height*offset)/100)-(height*95/100/2))
                + "px )";
                contactusPage.style.transform += "rotate( 0rad )";
            } else {
                contactusPage.style.transform = "translate("
                + (pages[5].bodies[0].position.x-(width*95/100/2)) 
                + "px, "
                + (pages[5].bodies[0].position.y-(height*95/100/2))
                + "px )";
                contactusPage.style.transform += "rotate( " + pages[5].bodies[0].angle + "rad )";
            }

            window.requestAnimationFrame(update);
        }

        function centerLogo(){
            if (isMobile || (window.innerHeight > window.innerWidth)){
                if (window.innerHeight > window.innerWidth) {
                    Body.setPosition(logoBall, {x:width*50/100, y:height*15/100});
                    anchor.x = width*50/100;
                    anchor.y = height*15/100;
                } else {
                    Body.setPosition(logoBall, {x:width*50/100, y:height*20/100});
                    anchor.x = width*50/100;
                    anchor.y = height*20/100;
                }
            } else {
                Body.setPosition(logoBall, {x:width*50/100, y:height*15/100});
                anchor.x = width*50/100;
                anchor.y = height*20/100;
            }
        }

        window.addEventListener('hashchange', function(){
            setHash();
        })

        function setHash() {
            hash = window.location.hash;
            console.log(hash);
            var page;
            if (hash.length !== 0){
                //console.log(location[1]);
                if (hash === '#a'){
                    page = _pagesettings[1];
                    focusObject(page, 1);
                } else if (hash === '#p'){
                    page = _pagesettings[2];
                    focusObject(page, 2);
                } else if (hash === '#r'){
                    page = _pagesettings[3];
                    focusObject(page, 3);
                } else if (hash === '#l'){
                    page = _pagesettings[4];
                    focusObject(page, 4);
                } else if (hash === '#co'){
                    page = _pagesettings[5];
                    focusObject(page, 5);
                } else {
                    page = _pagesettings[0];
                    focusObject(page, 0);
                }
            } else {
                page = _pagesettings[0];
                focusObject(page, 0);
            }
        }

        var params = new URLSearchParams(window.location.search);
        var project = params.get('qr');
        //console.log(project);
        if (project != null){
            var page = _pagesettings[2];
            window.location.hash = '#p';
            focusObject(page, 2);
        }


        function resizeElements(){
            if (isMobile || (window.innerHeight > window.innerWidth)){
                if (window.innerHeight > window.innerWidth){
                    radius = Math.round(window.innerHeight*circleSize/100/2);
                    largeRadius = Math.round(window.innerHeight*15/100/2);
                } else {
                    radius = Math.round(window.innerWidth*circleSize/100/2);
                    largeRadius = Math.round(window.innerHeight*30/100/2);
                }
            } else {
                radius = Math.round(window.innerWidth*circleSize/100/2);
                largeRadius = Math.round(window.innerHeight*30/100/2);
            }
            if (isMobile || (window.innerHeight > window.innerWidth)){
                if (window.innerHeight > window.innerWidth){
                    characterWidth = largeRadius*0.4;
                }
            } else {
                characterWidth = largeRadius*0.5;
            }

            //Resize images of buttons
            logoBall.render.sprite.xScale = largeRadius/334*2;
            logoBall.render.sprite.yScale = largeRadius/334*2;
            document.getElementById('aboutButton').style.height = radius*2+"px";
            document.getElementById('aboutButton').style.width = radius*2+"px";
            document.getElementById('registerButton').style.height = radius*2*1.2+"px";
            document.getElementById('registerButton').style.width = radius*2*1.2+"px";
            document.getElementById('learnButton').style.height = radius*2+"px";
            document.getElementById('learnButton').style.width = radius*2+"px";

            //Resize page divs
            document.getElementById('aboutuspage').style.height = window.innerHeight*0.95+"px";
            document.getElementById('projectspage').style.height = window.innerHeight*0.95+"px";
            document.getElementById('registerpage').style.height = window.innerHeight*0.95+"px";
            if (window.innerWidth < 1050){
                document.getElementById('registerpage').style.width = window.innerWidth*0.95+"px";
                document.getElementById('aboutuspage').style.width = window.innerWidth*0.95+"px";
                document.getElementById('projectspage').style.width = window.innerWidth*0.95+"px";
            } else {
                document.getElementById('registerpage').style.width = "1000px";
                document.getElementById('aboutuspage').style.width = "900px";
                document.getElementById('projectspage').style.width = "1000px";
            }
            document.getElementById('learningpage').style.height = window.innerHeight*0.95+"px";
            document.getElementById('learningpage').style.width = window.innerWidth*0.95+"px";
            document.getElementById('contactuspage').style.height = window.innerHeight*0.95+"px";
            document.getElementById('contactuspage').style.width = window.innerWidth*0.95+"px";

            //Resize canvas/world bounds for going from a smaller screen to larger screen
            render.bounds.max.x = window.innerWidth;
            render.bounds.max.y = window.innerHeight;
            render.options.width = window.innerWidth;
            render.options.height = window.innerHeight;
            render.canvas.width = window.innerWidth;
            render.canvas.height = window.innerHeight;

            Render.setPixelRatio(render, 'auto');
            mouse.pixelRatio = devicePixelRatio;

            //Reposition the bounding walls for the characters
            Body.setPosition(ceiling, {x:window.innerWidth/2, y:-window.innerHeight*0.2});
            Body.setPosition(left, {x:-window.innerWidth*0.2, y:window.innerHeight/2});
            Body.setPosition(floor, {x:window.innerWidth/2, y:window.innerHeight*1.2});
            Body.setPosition(right, {x:window.innerWidth*1.2, y:window.innerHeight/2});

            //Scale all circle bodies in Matter.js based on the change in radius calculated
            var changeScale = radius/startingRadius;
            Body.scale(logoBall, changeScale, changeScale);
            for (i = 1; i < 4; i++){
                Body.scale(pages[0].bodies[i], changeScale, changeScale);
            }
            for (i = 0; i < 20; i++){
                Body.scale(characters[i], changeScale, changeScale);
                characters[i].render.sprite.xScale = (characterWidth*sizes[i])*2/dimensions[i];
                characters[i].render.sprite.yScale = (characterWidth*sizes[i])*2/dimensions[i];
                
            }
            for(i = 0; i < 25; i++){
                Body.scale(balls[i], changeScale, changeScale);
            }
            startingRadius = radius;

            //Scale all rectangle bodies in Matter.js based on the change in screen size
            var heightRatio = window.innerHeight/startingHeight;
            var widthRatio = window.innerWidth/startingWidth;
            Body.scale(pages[0].bodies[0], widthRatio, heightRatio);
            for (i = 4; i<7; i++){
                Body.scale(pages[0].bodies[i], widthRatio, heightRatio);
            }
            for (i = 1; i<6; i++){
                Body.scale(pages[i].bodies[0], widthRatio, heightRatio);
            }
            startingHeight = window.innerHeight;
            startingWidth = window.innerWidth;
        }

        window.addEventListener('resize', function(event){
            //console.log("Before", width, height);
            width = window.innerWidth;
            height = window.innerHeight;
            //console.log("After", width, height);
            checkTallPage();
            resizeElements();
            refocusObject(pageHistory[numOfPages-1][0], pageHistory[numOfPages-1][1]);
            centerLogo();
            //positionBackButton();
        });

        var input = document.getElementById("hackText");
        if (!isMobile){
            input.focus();
            input.select();
        }
        document.addEventListener('touchstart', this.handleTouchStart);
        
    }

    handleTouchStart = (e) => {
        // Check if the touched element is not inside the input area
        if (!e.target.closest('#hackText')) {
          e.preventDefault();
        }
      };

    redirectAboutUs (){
        document.getElementById('about').click();
    }

    hoverAbout (){
        document.getElementById('aboutButton').setAttribute('src', './imgs/Buttons/AboutHover.png')
    }
    unhoverAbout (){
        document.getElementById('aboutButton').setAttribute('src', './imgs/Buttons/About.png')
    }

    redirectRegister (){
        document.getElementById('registerNow').click();
    }
    hoverRegister (){
        document.getElementById('registerButton').setAttribute('src', './imgs/Buttons/RegisterHover.png')
    }
    unhoverRegister (){
        document.getElementById('registerButton').setAttribute('src', './imgs/Buttons/Register.png')
    }

    redirectLearn (){
        document.getElementById('learning').click();
    }
    hoverLearn (){
        document.getElementById('learnButton').setAttribute('src', './imgs/Buttons/LearnHover.png')
    }
    unhoverLearn (){
        document.getElementById('learnButton').setAttribute('src', './imgs/Buttons/Learn.png')
    }

    tryHack (){
        if(window.event.key === 'Enter' && document.getElementById('hackText').value !== "") {
            console.log(document.getElementById('hackText').value);
            if (document.getElementById('hackText').value.toLowerCase().trim() === "clear"){
                hacksTried = "";
            } else if (document.getElementById('hackText').value.toLowerCase().trim() === "reset"){
                hacksTried += "<br>&#62;reset";
                document.getElementsByTagName("body")[0].style.backgroundColor = 'white';
                document.getElementById("websiteHack").style.color = 'black';
                document.getElementById("hackText").style.color = 'black';
                //console.log(startingRadius, radius);
                for(var i = 0; i < 25; i++){
                    balls[i].render.fillStyle = ballColours[i%6];
                    balls[i].render.opacity = 0.8;
                    var changeScale = radius/startingRadius;
                    Body.scale(balls[i], changeScale, changeScale);
                }
                startingRadius = radius;
                globalAngle = 0;
                document.getElementById("aboutButton").style.transform = "rotate(0deg)";
                document.getElementById("learnButton").style.transform = "rotate(0deg)";
                document.getElementById("registerButton").style.transform = "rotate(0deg)";
            } else {
                hacksTried += "<br>&#62;" + document.getElementById('hackText').value;
                //Check if hack is valid
                var attempt = document.getElementById('hackText').value.toLowerCase().trim().split(" ");
                if (attempt[0] === "help" && attempt.length === 1){
                    hacksTried += "<br>Try these :)<br>ballcolor [color]<br>ballopacity [decimal]<br>clear<br>nudge<br>rotate [degrees]<br>";
                }
                else if (attempt[0] === "help" && attempt[1] === "please" && attempt.length === 2){
                    hacksTried += "<br>Such nice manners! Try these :)<br>ballcolor [color]<br>ballopacity [decimal]<br>ballsize [scale]<br>clear<br>nudge<br>reset<br>rotate [degrees]<br>velocity [degrees] [magnitude]";
                }
                else if (attempt[0] === "bgcolor" && attempt.length === 2){
                    document.getElementsByTagName("body")[0].style.backgroundColor = attempt[1];  
                    if (attempt[1] === "black" || attempt[1] === "darkblue"){
                        document.getElementById("websiteHack").style.color = 'white';
                        document.getElementById("hackText").style.color = 'white';
                    } else {
                        document.getElementById("websiteHack").style.color = 'black';
                        document.getElementById("hackText").style.color = 'black';
                    }
                    if (attempt[1][0] === "#" && attempt[1].length === 7){
                        var r, g, b, hsp;
                        if (!attempt[1].match(/^rgb/)) {
                            // If HEX --> store the red, green, blue values in separate variables
                            var color = +("0x" + attempt[1].slice(1).replace( attempt[1].length < 5 && /./g, '$&$&'));
                        
                                r = color >> 16;
                                g = (color >> 8) & 255;
                                b = color & 255;
                        } // HSP equation from http://alienryderflex.com/hsp.html
                        hsp = Math.sqrt( 0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
                      
                        // Using the HSP value, determine whether the color is light or dark
                        if (hsp>127.5) {
                            //light color
                            document.getElementById("websiteHack").style.color = 'black';
                            document.getElementById("hackText").style.color = 'black';
                        } 
                        else {
                            //dark color
                            document.getElementById("websiteHack").style.color = 'white';
                            document.getElementById("hackText").style.color = 'white';
                        }
                    }
                }
                else if (attempt[0] === "ballcolor" && attempt.length === 2){
                    if (attempt[1] === "normal" || attempt[1] === "original"){
                        for(i = 0; i < 25; i++){
                            balls[i].render.fillStyle = ballColours[i%6];
                        }
                    } else {
                        for(i = 0; i < 25; i++){
                            balls[i].render.fillStyle = attempt[1];
                        }
                    }
                } 
                else if (attempt[0] === "ballopacity" && attempt.length === 2){
                    for(i = 0; i < 25; i++){
                        balls[i].render.opacity = attempt[1];
                    }
                }
                else if (attempt[0] === "ballsize" && attempt.length === 2) {
                    changeScale = (attempt[1]*startingRadius)/startingRadius;
                    for(i = 0; i < 25; i++){
                        Body.scale(balls[i], changeScale, changeScale);
                    }
                    startingRadius = attempt[1]*startingRadius;
                }
                else if (attempt[0] === "rotate" && attempt.length === 2){
                    globalAngle = parseFloat(((parseFloat(attempt[1]) * Math.PI)/180).toFixed(2));
                    document.getElementById("aboutButton").style.transform = "rotate("+attempt[1]+"deg)";
                    document.getElementById("learnButton").style.transform = "rotate("+attempt[1]+"deg)";
                    document.getElementById("registerButton").style.transform = "rotate("+attempt[1]+"deg)";
                }
                else if (attempt[0] === "nudge" && attempt.length === 1){
                    for(i = 0; i < 24; i++){
                        Body.setVelocity(balls[i], {x:Math.random()*15-7, y:Math.random()*15-7});
                        Body.setVelocity(characters[i], {x:Math.random()*15-7, y:Math.random()*15-7});
                    }
                } else if (attempt[0] === "velocity" && attempt.length === 3){
                    var xvelocity = attempt[2]*Math.cos(attempt[1]);
                    var yvelocity = attempt[2]*Math.sin(attempt[1]);
                    for(i = 0; i < 24; i++){
                        Body.setVelocity(balls[i], {x:xvelocity, y:yvelocity});
                        Body.setVelocity(characters[i], {x:xvelocity, y:xvelocity});
                    }
                    
                } else if (attempt[0] === "hi" || attempt[0] === "hello" || attempt[0] === "hey"){
                    hacksTried += "<br>Well hello to you too! Not sure what to do? Try typing \"help\" for suggestions :)";
                }
                
                else {
                    hacksTried += "<br>'" + document.getElementById('hackText').value +"' is not recognized as a command, try typing \"help\" for suggestions :)";
                }
            }
            document.getElementById('hacksTried').innerHTML = hacksTried;
            document.getElementById('hackText').value = "";
        }
    }

    componentWillUnmount(){
        Runner.stop(runner);
        Render.stop(render);
        console.log("landing removed");
    }

    render() {
        return (
            <div>
                <div id="confetti-container" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 0 }} ></div>
                <div id='main' ref={this.scene} >
                    <canvas id='world' ref={this.canvas} />
                    <div id='bdayPage' class="pages" style={{zIndex:2, width: this.state.width*0.5+"px", height:this.state.height*0.55+"px"}}><Birthday/></div>
                    <div id="menu" class="menu">
                        <div class='menu-content'>
                            <br></br>
                            <a href="#h"><button class='option' id='home'>Home</button></a>
                            <a href="#a"><button class='option' id='about'>About Us</button></a>
                            <a href="#c"><button class='option' id='camps' style={{display:'none'}}>Camps</button></a>
                            <a href="#r"><button class='option' id='registerNow'>Register Now</button></a>
                            <a href="#l"><button class='option' id='learning'>Online Learning</button></a>
                            <a href="#co"><button class='option' id='contactus'>Contact Us</button></a>
                        </div>
                        <div id='menu-social-media'>
                            <a href="https://www.facebook.com/bytecamp" class="socialMedia"><img alt='Byte Camp on Facebook' src='./imgs/SocialMedia/fb.png'/></a>
                            <a href="https://www.instagram.com/bytecamp" class="socialMedia"><img alt='Byte Camp on Instagram' src='./imgs/SocialMedia/insta.png'/></a>
                            <a href="https://www.youtube.com/bytecamp" class="socialMedia"><img alt='Byte Camp on Youtube' src='./imgs/SocialMedia/youtube.png'/></a>
                        </div>
                        <div id="menu-symbol">&#x2026;</div>
                    </div>

                    <div id='aboutus' class="boxes" ><a href="#a" onClick={this.redirectAboutUs} id="main-buttons"><img id='aboutButton' alt="Go to our About Us page" onMouseOver={this.hoverAbout} onMouseOut={this.unhoverAbout} style={{width: this.state.diameter+"px", height:this.state.diameter+"px"}} src='./imgs/Buttons/About.png'/></a></div>
                    <div id='register' class="boxes" ><a href="#r" onClick={this.redirectRegister} id="main-buttons"><img id='registerButton' alt="Go to our registrationg page" onMouseOver={this.hoverRegister} onMouseOut={this.unhoverRegister} style={{width: this.state.diameter*1.2+"px", height:this.state.diameter*1.2+"px"}} src='./imgs/Buttons/Register.png'/></a></div>
                    <div id='learn' class="boxes" ><a href="#l" onClick={this.redirectLearn} id="main-buttons"><img id='learnButton' alt="Go to our online learning us page" onMouseOver={this.hoverLearn} onMouseOut={this.unhoverLearn} style={{width: this.state.diameter+"px", height:this.state.diameter+"px"}} src='./imgs/Buttons/Learn.png'/></a></div>
                    <div id='aboutuspage' class="pages" style={{width: this.state.width*95/100+"px", height:this.state.height*95/100+"px"}}><About/></div>
                    <div id='projectspage' class="pages" style={{width: this.state.width*95/100+"px", height:this.state.height*95/100+"px"}}><Projects/></div>
                    <div id='registerpage' class="pages" style={{width: this.state.width*95/100+"px", height:this.state.height*95/100+"px"}}><Register/></div>
                    <div id='learningpage' class="pages" style={{width: this.state.width*95/100+"px", height:this.state.height*95/100+"px"}}><Learn/></div>
                    <div id='contactuspage' class="pages" style={{width: this.state.width*95/100+"px", height:this.state.height*95/100+"px"}}><Contact/></div>

                    <div id='websiteHack' title="Hack our site! :o Type a command..."><div id="hacksTried"></div>&#62;<input type="text" id="hackText" name="hackText" onKeyDown={this.tryHack}/></div>

                    <style>{"\
                        .boxes{\
                            display:flex;\
                            position:absolute;\
                            justify-content: center;\
                            z-index: 1;\
                        }\
                        .pages{\
                            display:flex;\
                            position:absolute;\
                            z-index: 1;\
                        }\
                        #aboutuspage{\
                            background-color: transparent;\
                        }\
                        #projectspage{\
                            background-color: transparent;\
                        }\
                        #registerpage{\
                            border: 5px solid #ffffffff;\
                            background-color: white;\
                        }\
                        #learningpage{\
                            border: 5px solid #ffffffff;\
                            background-color: white;\
                        }\
                        #contactuspage{\
                            border: 5px solid #ffffffff;\
                            background-color: white;\
                        }\
                        #confetti-container {\
                        pointer-events: none; \
                        }\
                    "}</style>  
                </div>
            </div>
        );
    }
    
};

export default Main;
