
import React from 'react';
import './index.css';
import $ from 'jquery';
import { isMobile } from 'react-device-detect';

class Learn extends React.Component {

    componentDidMount(){

        //console.log($('.video').width());
        var videoHeight = $('.video').width()*0.5625+"px";
        $('.video').height(videoHeight);

        var descriptionHeight = $('.series').height()-$('.video').height()-15;
        $('.videoDescription').height(descriptionHeight);
        
        var randomVideos = ["https://www.youtube.com/embed/RhMeH_g9rl4", "https://www.youtube.com/embed/UU6_5i9P14A", "https://www.youtube.com/embed/I2L8qi15gys"];
        var videoTitles = ["Drawing with Inkscape","Dungeon Game with Godot","Video Editing with Kdenlive"];
        var videoInfo = [
            "Let us show you the tools and techniques to make your own cool characters in Inkscape, a free program available on Windows or macOS",
            "Learn to build a dungeon themed dodging game in Godot, a free editor to make video games for PC and Mobile",
            "Follow along as we show you how to use various features of Kdenlive to make your very own awesome skateboarding trick shot video"
        ]
        var swLinks = ["https://inkscape.org/", "https://godotengine.org/", "https://kdenlive.org/en/"];
        var swIcons = ["inkscape", "godot", "kdenlive"];
        var relCampsList = [
            "Learn in-person: <img class=\"swi\" title='Introduction to Coding' alt='Introduction to Coding' src='./calendar/icons/100x/coding.png'/><img class=\"swi\" title='2D Video Game Design' alt='2D Video Game Design' src='./calendar/icons/100x/flash.png'/><img class=\"swi\" title='Build an App' alt='Build an App' src='./calendar/icons/100x/ByteApp.png'/>",
            "Learn in-person: <img class=\"swi\" title='2D Video Game Design' alt='2D Video Game Design' src='./calendar/icons/100x/flash.png'/>",
            "Learn in-person: <img class=\"swi\" title='Music & Video Production' alt='Music & Video Production' src='./calendar/icons/100x/musicvid.png'/><img class=\"swi\" title='Claymation' alt='Claymation' src='./calendar/icons/100x/clay.png'/><img class=\"swi\" title='2D Tablet Animation' alt='2D Tablet Animation' src='./calendar/icons/100x/2Dtab.png'/>"
        ]
        var videoPlaylists = ["https://www.youtube.com/playlist?list=PLnxTAW89Yd-T6ZQm9KX_081z9VG-6rOfS","https://www.youtube.com/playlist?list=PLnxTAW89Yd-QSzPcQD3_KeUK8-zxEvp7s","https://www.youtube.com/playlist?list=PLnxTAW89Yd-R0a5gPMJb07qTDa_684h6l"];
        
        if (!isMobile){
            document.getElementById('randomSeries').innerHTML = "<div id='randTitle' class='seriesTitle'></div><div class='video'><iframe loading='lazy' id='randSeries' width='100%' height='100%' src='https://www.youtube.com/embed/mXVzE8OoRGI' title='YouTube video player' ></iframe></div><div class='videoDescription'><div class='description-content'><div id='randInfo' class='info'></div><div id='randSW' class='software'></div><div id='randRelCamps' class='relCamps'></div><div class='seriesBtn'><a id='randPlaylist' href='#l' target='_blank' class='moreBtn' style=\"background-color:#fff\">Keep Watching</a></div></div></div>"
            var pickVideo = Math.floor(Math.random()*3);
            document.getElementById('randSeries').src = randomVideos[pickVideo];
            document.getElementById('randTitle').innerHTML = videoTitles[pickVideo];
            document.getElementById('randInfo').innerHTML = videoInfo[pickVideo];
            document.getElementById('randSW').innerHTML = "You'll need: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href=\""+ swLinks[pickVideo] +"\" alt=\""+ swIcons[pickVideo] +"\" target=\"_blank\"><img class=\"swi\" src='./calendar/icons/sw/"+ swIcons[pickVideo] +".png'/></a>";
            document.getElementById('randRelCamps').innerHTML = relCampsList[pickVideo];
            document.getElementById('randPlaylist').href = videoPlaylists[pickVideo];
            videoHeight = $('.video').width()*0.5625+"px";
            $('.video').height(videoHeight);
            descriptionHeight = $('.series').height()-$('.video').height()-40-40;
            $('.videoDescription').height(descriptionHeight);
        }

        window.addEventListener("resize", function(event){
            videoHeight = $('.video').width()*0.5625+"px";
            $('.video').height(videoHeight);
            var descriptionHeight = $('.series').height()-$('.video').height()-40-40;
            $('.videoDescription').height(descriptionHeight);
        })
    }

    render() {
        return (
            <div id='LearnContent'>
                <div id="tablet" class="series">
                    <div class="seriesTitle">2D Animation with FlipaClip</div>
                    <div class="video">
                        <iframe loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/mXVzE8OoRGI" title="2D Animation on Tablet" ></iframe>
                    </div>
                    <div class="videoDescription"><div class="description-content">
                        <div class="info">Follow along on your tablet or ipad as we walk you through animating using a free program called FlipaClip</div>
                        <div class="software">You'll need: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://play.google.com/store/apps/details?id=com.vblast.flipaclip&hl=en_CA" title="FlipaClip" alt="FlipaClip" target="_blank"><img alt="Download FlipaClip" class="swi" src='./calendar/icons/sw/flipaclip.png'/></a></div>
                        <div class="relCamps">Learn in-person: <img class="swi" title="2D Tablet Animation" alt="2D Tablet Animation" src='./calendar/icons/100x/2Dtab.png'/></div>
                        <div class="seriesBtn"><a href="https://www.youtube.com/playlist?list=PLnxTAW89Yd-Qwbil6lliOFRpWKGK5JDvv" alt="2D Animation on Tablet Series on Youtube" target="_blank" class="moreBtn" style={{backgroundColor:"#fff"}}>Keep Watching</a></div>
                    </div></div>
                </div>
                <div id="scratch" class="series">
                    <div class="seriesTitle">Coding with Scratch</div>
                    <div class="video">
                        <iframe loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/OFRZke_-Z3c" title="YouTube video player" ></iframe>
                    </div>
                    <div class="videoDescription"><div class="description-content">
                        <div class="info">Learn how to build your very own mini mario game using Scratch, a free online program on Windows or macOS</div>
                        <div class="software">You'll need: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://scratch.mit.edu/" title="Scratch" alt="Scratch" target="_blank"><img alt="Download Scratch" class="swi" src='./calendar/icons/sw/scratch.png'/></a></div>
                        <div class="relCamps">Learn in-person: <img class="swi" title="Introduction to Coding" alt="Introduction to Coding" src='./calendar/icons/100x/coding.png'/></div>
                        <div class="seriesBtn"><a href="https://www.youtube.com/playlist?list=PLnxTAW89Yd-THQ_RdyIn6YXty2J48KWfL" alt="Mini Mario Game on Scratch Series on Youtube" target="_blank" class="moreBtn" style={{backgroundColor:"#fff"}}>Keep Watching</a></div>
                    </div></div>
                </div>
                <div id="randomSeries" class="series">
                    
                </div>
                <style>{"\
                #LearnContent{\
                    width: 100%;\
                    height: 100%;\
                }\
                "}</style>  
            </div>
        );
    }
    
};


export default Learn;