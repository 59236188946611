
import React, { useEffect, useState } from 'react';
import './projects.css';

const EmbeddedGame = () => {
    const [gameUrl, setGameUrl] = useState("");

    useEffect(() => {
       
        window.addEventListener("message", function(event){
            if (event.data.action === "changeURL"){
                window.location.href = event.data.url;
            }

        } );

        const urlParams = new URLSearchParams(window.location.search);

        const gameParameter = urlParams.get("qr");

        const url = `https://www.bytecamp.ca/kids/index_2022.php?code=${gameParameter}`;
        setGameUrl(url);

        return () => {
            window.removeEventListener("message");
        };

    }, []);

    return (
        <div id='projectContent'>
            <iframe id="projectPage" src={gameUrl}></iframe>
        </div>
    )
}

export default EmbeddedGame;

/*class Projects extends React.Component{

    componentDidMount(){
        var params = new URLSearchParams(window.location.search);
        var project = params.get('qr');
        if (project != null){
            document.getElementById("projectPage").src = "https://www.bytecamp.ca/kids/index_2022.php?code="+project;
        }

        const receiveMessage = () => {
            document.header("Cross-Origin-Embedder-Policy", "require-corp");
            document.header("Cross-Origin-Opener-Policy", "same-origin");
        }
       
        window.addEventListener("message", receiveMessage);
            
    }

    render(){
        return (
            <div id='projectContent'>
                <iframe id="projectPage" src=""></iframe>
            </div>
        );
    }
    
};

export default Projects;*/