import React from 'react';
import Landing from './scenes/Landing';

const originalConsoleError = console.error;
console.error = (...args) => {
    if (args[0].startsWith('Warning:')) {
        return;
    }
    originalConsoleError(...args);
};

class App extends React.Component {

  constructor(props){
    super(props);
    this.state = {};
  }

  componentDidMount() {

      document.getElementById("root").addEventListener('wheel', event => {
        if (event.ctrlKey){
          event.preventDefault()
        }
      }, true);
  }

  render () {
    return (
      <div className="App" id="App">
        <Landing />
      </div>
    );
  }
}

export default App;
